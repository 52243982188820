<template>
  <v-card>
    <v-card-title>
      <v-row class="pa-3" justify="space-between">
        <h3>
          {{
            `${assessmentForm.isUpdating ? "Update" : "Add New"} ${ucFirst(
              type
            )}`
          }}
        </h3>
        <v-dialog v-model="resetFormDialog" width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="!submitLoading"
              dark
              color="red accent-4"
              depressed
              small
              :ripple="false"
              v-bind="attrs"
              v-on="on"
            >
              Reset Form
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="main-red-bg py-2 px-4">
              <small class="white--text"> Reset Form ? </small>
              <v-btn
                @click="resetFormDialog = false"
                class="mr-n2"
                color="white"
                icon
                absolute
                right
                :ripple="false"
              >
                <v-icon size="15">mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="pa-4">
              This will reset all input to it's initial state, Proceed?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
              <v-btn
                @click="resetFormDialog = false"
                dark
                color="blue accent-4"
                :ripple="false"
              >
                Cancel
              </v-btn>
              <v-btn
                @click="resetForm"
                dark
                color="red accent-4"
                :ripple="false"
              >
                Reset
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      <small>{{
        `GRADE ${subject.gradeLevel} | ${subject.name.toUpperCase()}`
      }}</small>
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text v-if="submitLoading">
      <small v-show="uploadCount > 0">
        {{ `Uploading images : ${uploadCount} of ${blobs.length}` }}
      </small>
      <v-progress-linear
        :indeterminate="uploadCount === 0"
        :value="uploadProgress"
        color="blue"
        height="25"
      >
        <template v-slot:default="{ value }">
          <strong>{{
            `${
              uploadCount > 0
                ? value + "%"
                : "Please wait while form is being submitted..."
            }`
          }}</strong>
        </template>
      </v-progress-linear>
    </v-card-text>
    <v-form v-else v-model="assessmentFormValid">
      <v-col cols="12">
        <v-row align="baseline" class="mx-md-2">
          <v-switch
            @change="cacheData('offline', $event)"
            :input-value="assessmentForm.offline"
            color="green"
            inset
            dense
            hide-details
            label="On-Site Assessment"
          />
          <v-spacer></v-spacer>
          <v-textarea
            v-if="assessmentForm.offline"
            @change="cacheData('accessCode', $event)"
            :value="assessmentForm.accessCode"
            dense
            label="Access Code : "
            outlined
            auto-grow
            rows="1"
            :rules="[validators.required]"
          >
            <template slot="append">
              <v-btn
                icon
                x-small
                @click="generateRandomKey()"
              >
                <v-icon>mdi-key-variant</v-icon>
              </v-btn>              
            </template>
          </v-textarea>
        </v-row>
      </v-col>
      <v-card-text>
        <v-textarea
          @change="cacheData('title', $event)"
          :value="assessmentForm.title"
          dense
          label="Title : "
          outlined
          auto-grow
          rows="1"
          :rules="[validators.required]"
        />
        <v-textarea
          @change="cacheData('lesson', $event)"
          :value="assessmentForm.lesson"
          dense
          label="Lesson : "
          outlined
          auto-grow
          :rules="[validators.required]"
        />
        <v-row>
          <v-col cols="4" sm="2">
            <v-select
              class="mb-n8"
              @change="cacheData('quarter', $event)"
              :value="assessmentForm.quarter"
              :items="quarterOptions"
              dense
              label="Quarter : "
              outlined
              :rules="[validators.required]"
            />
          </v-col>
          <v-col cols="4" sm="2">
            <v-text-field
              class="mb-n8"
              type="number"
              @input="cacheData('attempts', $event)"
              :value="assessmentForm.attempts"
              dense
              label="Attempts : "
              outlined
              :rules="[validators.required, validators.requiredNumber]"
            />
          </v-col>
          <v-col cols="4" sm="2">
            <v-text-field
              @change="cacheData('duration', $event)"
              class="mb-n8"
              :value="assessmentForm.duration"
              dense
              label="Duration : "
              hint="In minutes"
              outlined
              :rules="[validators.required]"
            >
              <template slot="append">
                <v-icon size="20">mdi-clock</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col v-if="assessmentForm.attempts > 1" cols="12" sm="6">
            <v-select
              class="mb-n8"
              @input="cacheData('recordHighest', $event)"
              :value="assessmentForm.recordHighest"
              :items="[
                { text: 'Highest Score', value: true },
                { text: 'Last Attempt', value: false },
              ]"
              dense
              label="Accept : "
              outlined
              :rules="[validators.required]"
            />
          </v-col>
          <v-col v-else cols="12" sm="6">
            <v-row justify="space-around" class="mx-md-5">
              <v-switch
                @change="cacheData('status', $event)"
                :input-value="assessmentForm.status"
                color="green"
                inset
                dense
                hide-details
                label="Active"
              />
              <v-switch
                @change="cacheData('recorded', $event)"
                :input-value="assessmentForm.recorded"
                color="green"
                inset
                dense
                hide-details
                label="Recorded"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mb-3" align="center" align-md="start">
          <v-col cols="12" sm="6" order="2" order-sm="1">
            <p class="ml-2 mb-0">
              Schedule (Optional)
              <v-icon
                class="mr-1"
                @click="expandSchedule = !expandSchedule"
                slot="append"
                size="20"
                right
                >mdi-{{ expandSchedule ? "minus" : "plus" }}</v-icon
              >
            </p>
            <v-expand-transition>
              <div v-show="expandSchedule">
                <div class="d-flex justify-end">
                  <v-btn
                    v-show="assessmentForm.from"
                    @click="clearSchedule"
                    x-small
                    outlined
                    plain
                    depressed
                    >Clear Schedule
                    <v-icon size="15" right>mdi-close</v-icon>
                  </v-btn>
                </div>
                <v-row
                  class="px-2"
                  :dense="$vuetify.breakpoint.smAndUp ? false : true"
                >
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :value="assessmentForm.from"
                      label="From"
                      readonly
                      :rules="[
                        requireTimeInput(
                          assessmentForm.monthFrom,
                          assessmentForm.timeFrom
                        ),
                      ]"
                    >
                      <template slot="append">
                        <v-menu
                          v-model="monthFromMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mr-1"
                              icon
                              x-small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon size="20">mdi-calendar</v-icon>
                            </v-btn>
                          </template>
                          <v-date-picker
                            :min="new Date().toISOString()"
                            :width="$vuetify.breakpoint.xsOnly ? '260' : '290'"
                            :value="assessmentForm.monthFrom"
                            @input="cacheData('monthFrom', $event)"
                          ></v-date-picker>
                        </v-menu>
                        <v-menu
                          v-model="timeFromMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="!assessmentForm.monthFrom"
                              icon
                              x-small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon size="20">mdi-clock</v-icon>
                            </v-btn>
                          </template>
                          <v-time-picker
                            ampm-in-title
                            :width="$vuetify.breakpoint.xsOnly ? '260' : '290'"
                            :value="assessmentForm.timeFrom"
                            @change="cacheData('timeFrom', $event)"
                          ></v-time-picker>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :value="assessmentForm.to"
                      label="To"
                      readonly
                      :rules="[
                        requireTimeInput(
                          assessmentForm.monthTo,
                          assessmentForm.timeTo
                        ),
                      ]"
                    >
                      <template slot="append">
                        <v-menu
                          v-model="monthToMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="
                                !assessmentForm.monthFrom ||
                                !assessmentForm.timeFrom
                              "
                              class="mr-1"
                              icon
                              x-small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon size="20">mdi-calendar</v-icon>
                            </v-btn>
                          </template>
                          <v-date-picker
                            :min="assessmentForm.monthFrom"
                            :width="$vuetify.breakpoint.xsOnly ? '260' : '290'"
                            :value="assessmentForm.monthTo"
                            @input="cacheData('monthTo', $event)"
                          ></v-date-picker>
                        </v-menu>
                        <v-menu
                          v-model="timeToMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="!assessmentForm.monthTo"
                              icon
                              x-small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon size="20">mdi-clock</v-icon>
                            </v-btn>
                          </template>
                          <v-time-picker
                            ampm-in-title
                            :min="
                              assessmentForm.monthFrom ===
                              assessmentForm.monthTo
                                ? assessmentForm.timeFrom
                                : ''
                            "
                            :width="$vuetify.breakpoint.xsOnly ? '260' : '290'"
                            :value="assessmentForm.timeTo"
                            @change="cacheData('timeTo', $event)"
                          ></v-time-picker>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-col>
          <v-col cols="12" sm="6" order="1" order-sm="2">
            <v-row
              v-if="assessmentForm.attempts > 1"
              justify="space-around"
              class="mx-md-5 mt-n5 mb-3"
            >
              <v-switch
                @change="cacheData('status', $event)"
                :input-value="assessmentForm.status"
                color="green"
                inset
                dense
                hide-details
                label="Active"
              />
              <v-switch
                @change="cacheData('recorded', $event)"
                :input-value="assessmentForm.recorded"
                color="green"
                inset
                dense
                hide-details
                label="Recorded"
              />
            </v-row>
            <v-expand-transition>
              <v-row
                v-if="assessmentForm.recorded"
                class="ml-0"
                justify="start"
                justify-md="center"
              >
                <v-checkbox
                  class="mr-3"
                  @change="
                    cacheData('performanceTask', $event ? $event : false)
                  "
                  :value="assessmentForm.performanceTask"
                  color="green"
                  dense
                  hide-details
                  label="Performance Task"
                />
                <v-checkbox
                  @change="cacheData('writtenWork', $event ? $event : false)"
                  :value="assessmentForm.writtenWork"
                  color="green"
                  dense
                  hide-details
                  label="Written Work"
                />
              </v-row>
            </v-expand-transition>
          </v-col>
        </v-row>
        <v-card
          class="mt-5"
          elevation="4"
          v-for="(test, testIndex) in assessmentForm.assessmentTests"
          :key="testIndex"
        >
          <v-card-title>
            <v-row class="pl-3 py-2" align="start" justify="space-between">
              <h5>Test {{ testIndex + 1 }}</h5>
              <v-item-group>
                <v-btn
                  @click="test.expand = !test.expand"
                  text
                  :small="$vuetify.breakpoint.smAndUp ? true : false"
                  :x-small="$vuetify.breakpoint.xsOnly ? true : false"
                  :ripple="false"
                >
                  <v-icon :size="$vuetify.breakpoint.smAndUp ? '25' : '20'">
                    mdi-window-{{ test.expand ? "minimize" : "restore" }}
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="assessmentForm.assessmentTests.length > 1"
                  @click="removeTest(testIndex)"
                  text
                  :small="$vuetify.breakpoint.smAndUp ? true : false"
                  :x-small="$vuetify.breakpoint.xsOnly ? true : false"
                  :ripple="false"
                >
                  <v-icon :size="$vuetify.breakpoint.smAndUp ? '25' : '20'"
                    >mdi-close</v-icon
                  >
                </v-btn>
              </v-item-group>
            </v-row>
          </v-card-title>
          <v-card-subtitle>
            <small>{{
              test.test ? testType(test.method, test.test) : ""
            }}</small>
          </v-card-subtitle>
          <v-expand-transition>
            <div v-show="test.expand">
              <v-divider></v-divider>
              <v-card-subtitle>
                <v-row
                  align-sm="center"
                  justify="start"
                  justify-sm="space-around"
                  class="pa-3"
                >
                  <v-switch
                    class="mx-3 mb-2"
                    @change="cacheTestsData('method', testIndex, $event)"
                    :input-value="test.method"
                    true-value="AUTOMATIC"
                    false-value="MANUAL"
                    color="green"
                    inset
                    dense
                    :label="`GRADING METHOD : ${test.method}`"
                  />
                  <v-select
                    class="mb-n5"
                    @change="cacheTestsData('test', testIndex, $event)"
                    :value="test.test"
                    :items="testOptions[test.method]"
                    dense
                    label="Test Type : "
                    outlined
                    :rules="[validators.required]"
                  />
                </v-row>
              </v-card-subtitle>
              <v-card-text>
                <v-textarea
                  @change="cacheTestsData('instruction', testIndex, $event)"
                  :value="test.instruction"
                  dense
                  label="Instruction : "
                  outlined
                  rows="2"
                  auto-grow
                  :rules="[validators.required]"
                />
                <v-row>
                  <v-col cols="12" sm="5">
                    <v-text-field
                      class="mb-n5"
                      type="number"
                      @input="cacheTestsData('totalItems', testIndex, $event)"
                      :value="test.totalItems"
                      dense
                      label="Number of Items : "
                      outlined
                      :rules="[validators.required, validators.requiredNumber]"
                    />
                  </v-col>
                  <v-col cols="12" sm="7">
                    <v-row justify="start" class="mx-3 mt-n3 mb-3" dense>
                      <v-switch
                        class="mr-5"
                        @change="cacheTestsData('random', testIndex, $event)"
                        :input-value="test.random"
                        color="green"
                        inset
                        dense
                        hide-details
                        label="Randomize Items"
                      />
                      <v-switch
                        v-show="test.test === 'multiplechoice'"
                        @change="
                          cacheTestsData('imageChoices', testIndex, $event)
                        "
                        :input-value="test.imageChoices"
                        color="green"
                        inset
                        dense
                        hide-details
                        label="Image as Choices"
                      />
                      <v-switch
                        v-show="
                          test.test !== 'multiplechoice' &&
                          test.test !== 'matchingtype' &&
                          test.test !== 'trueorfalse' &&
                          test.test !== 'file' &&
                          test.test !== 'image'
                        "
                        @change="
                          cacheTestsData('caseSensitivity', testIndex, $event)
                        "
                        :input-value="test.caseSensitivity"
                        color="green"
                        inset
                        dense
                        hide-details
                        label="Case Sensitive"
                      />
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-show="test.questions.length > 0 && test.test">
                  <v-col
                    cols="12"
                    v-for="(question, questionIndex) in test.questions"
                    :key="questionIndex"
                  >
                    <v-card outlined>
                      <v-card-subtitle>
                        <v-row class="py-2 px-3 mb-n7" justify="space-between">
                          <h4 class="py-2">
                            {{ `Question #${questionIndex + 1}` }}
                          </h4>
                          <v-text-field
                            style="max-width: 90px"
                            type="number"
                            @change="
                              cacheQuestionsData(
                                'points',
                                testIndex,
                                questionIndex,
                                $event
                              )
                            "
                            :value="question.points"
                            :label="`Point${question.points > 1 ? 's' : ''} : `"
                            dense
                            outlined
                            :rules="[
                              validators.required,
                              validators.requiredNumber,
                              divisibleByEnumerateCount(
                                question.points,
                                question.enumerateCount,
                                test.test
                              ),
                            ]"
                          />
                        </v-row>
                      </v-card-subtitle>
                      <v-card-text>
                        <v-textarea
                          @change="
                            cacheQuestionsData(
                              'question',
                              testIndex,
                              questionIndex,
                              $event
                            )
                          "
                          :value="question.question"
                          dense
                          label="Question : "
                          outlined
                          auto-grow
                          rows="1"
                          :rules="[validators.required]"
                        >
                          <template slot="append-outer">
                            <v-file-input
                              class="my-n2"
                              style="max-width: 20px"
                              @change="
                                handleFileUploads(
                                  'question',
                                  testIndex,
                                  questionIndex,
                                  $event
                                )
                              "
                              :value="
                                getBlob(
                                  `${testIndex}${questionIndex}`,
                                  'blob',
                                  question.blob
                                )
                              "
                              accept="image/*"
                              dense
                              hide-details
                              hide-input
                              solo
                              prepend-icon="mdi-camera"
                            />
                          </template>
                          <template
                            :slot="test.test === 'enumeration' ? 'prepend' : ''"
                          >
                            <v-text-field
                              class="my-n2"
                              style="max-width: 115px"
                              type="number"
                              @change="
                                cacheQuestionsData(
                                  'enumerateCount',
                                  testIndex,
                                  questionIndex,
                                  $event
                                )
                              "
                              :value="question.enumerateCount"
                              label="Enumerate : "
                              dense
                              outlined
                              :rules="[
                                validators.required,
                                validators.requiredNumber,
                              ]"
                            />
                          </template>
                        </v-textarea>
                        <v-card
                          v-show="
                            getBlob(
                              `${testIndex}${questionIndex}`,
                              'preview',
                              question.preview
                            )
                          "
                          class="mb-3"
                          outlined
                        >
                          <v-card-subtitle>
                            <v-row class="pa-3" justify="space-between">
                              Image Preview :
                              <v-btn
                                v-show="
                                  getBlob(
                                    `${testIndex}${questionIndex}`,
                                    'blob',
                                    question.blob
                                  )
                                "
                                @click="
                                  cacheQuestionsData(
                                    'removeImage',
                                    testIndex,
                                    questionIndex,
                                    'question'
                                  )
                                "
                                color="red accent-4"
                                small
                                :outlined="
                                  $vuetify.breakpoint.smAndUp ? true : false
                                "
                                :icon="
                                  $vuetify.breakpoint.xsOnly ? true : false
                                "
                                dark
                              >
                                <v-icon
                                  v-if="$vuetify.breakpoint.xsOnly"
                                  size="25"
                                  >mdi-close-box</v-icon
                                >
                                <div v-else>Remove</div>
                              </v-btn>
                            </v-row>
                            <v-img
                              :src="
                                getBlob(
                                  `${testIndex}${questionIndex}`,
                                  'preview',
                                  question.preview
                                )
                              "
                              class="mt-3"
                              max-height="500"
                              contain
                            />
                          </v-card-subtitle>
                        </v-card>
                        <div v-if="test.method === 'AUTOMATIC'">
                          <div
                            v-if="
                              test.test === 'trueorfalse' ||
                              test.test === 'matchingtype'
                            "
                          >
                            <v-select
                              v-if="test.test === 'trueorfalse'"
                              @change="
                                cacheQuestionsData(
                                  'answer',
                                  testIndex,
                                  questionIndex,
                                  $event,
                                  0
                                )
                              "
                              :items="[
                                { text: 'TRUE', value: 'true' },
                                { text: 'FALSE', value: 'false' },
                              ]"
                              :value="
                                assessmentForm.assessmentTests[testIndex]
                                  .questions[questionIndex].answers[0].answer
                              "
                              dense
                              label="Answer"
                              outlined
                              hide-details
                            />
                            <v-textarea
                              v-else
                              class="mb-n5"
                              @change="
                                cacheQuestionsData(
                                  'answer',
                                  testIndex,
                                  questionIndex,
                                  $event,
                                  0
                                )
                              "
                              :value="
                                assessmentForm.assessmentTests[testIndex]
                                  .questions[questionIndex].answers[0].answer
                              "
                              dense
                              label="Answer : "
                              outlined
                              auto-grow
                              rows="1"
                              :rules="[validators.required]"
                            />
                          </div>
                          <v-card v-else outlined>
                            <v-card-subtitle>
                              <v-row
                                class="pa-4"
                                align="center"
                                justify="space-between"
                              >
                                {{
                                  test.test === "multiplechoice"
                                    ? "Choices"
                                    : "Answers"
                                }}
                                <v-item-group>
                                  <v-btn
                                    class="mr-1"
                                    @click="
                                      addAction(
                                        test.test,
                                        testIndex,
                                        questionIndex
                                      )
                                    "
                                    color="green"
                                    dark
                                    tile
                                    x-small
                                  >
                                    <v-icon size="15">mdi-plus</v-icon>
                                  </v-btn>
                                  <v-btn
                                    @click="
                                      removeAction(
                                        test.test,
                                        testIndex,
                                        questionIndex
                                      )
                                    "
                                    color="red"
                                    dark
                                    tile
                                    x-small
                                  >
                                    <v-icon size="15">mdi-minus</v-icon>
                                  </v-btn>
                                </v-item-group>
                              </v-row>
                            </v-card-subtitle>
                            <v-card-text>
                              <v-row
                                v-if="test.test === 'multiplechoice'"
                                align="center"
                                justify="start"
                              >
                                <v-col
                                  cols="12"
                                  sm="4"
                                  v-for="(
                                    choice, choiceIndex
                                  ) in question.choices"
                                  :key="choiceIndex"
                                >
                                  <v-textarea
                                    v-if="!test.imageChoices"
                                    class="mb-n5"
                                    @change="
                                      cacheQuestionsData(
                                        'choice',
                                        testIndex,
                                        questionIndex,
                                        $event,
                                        choiceIndex
                                      )
                                    "
                                    :value="choice.choice"
                                    :background-color="
                                      choice.correct ? 'green lighten-4' : ''
                                    "
                                    :hint="
                                      choice.correct ? 'Correct Answer' : ''
                                    "
                                    persistent-hint
                                    dense
                                    outlined
                                    auto-grow
                                    rows="1"
                                    :rules="[validators.required]"
                                  >
                                    <template slot="prepend-inner">
                                      <v-icon size="25"
                                        >mdi-alpha-{{
                                          intToChar(choiceIndex)
                                        }}</v-icon
                                      >
                                    </template>
                                    <template
                                      :slot="!choice.correct ? 'append' : ''"
                                    >
                                      <v-btn
                                        @click="
                                          cacheQuestionsData(
                                            'correct',
                                            testIndex,
                                            questionIndex,
                                            true,
                                            choiceIndex
                                          )
                                        "
                                        icon
                                        outlined
                                        x-small
                                      >
                                        <v-icon size="15">mdi-check</v-icon>
                                      </v-btn>
                                    </template>
                                  </v-textarea>
                                  <v-row v-else align="start">
                                    <v-col cols="12">
                                      <v-card
                                        class="mb-3"
                                        :style="
                                          choice.correct
                                            ? 'border: thin solid #1b5e20;'
                                            : ''
                                        "
                                        outlined
                                      >
                                        <v-card-subtitle>
                                          <v-row
                                            class="pa-3"
                                            justify="space-between"
                                          >
                                            <v-icon size="25"
                                              >mdi-alpha-{{
                                                intToChar(choiceIndex)
                                              }}</v-icon
                                            >
                                            <v-btn
                                              v-if="
                                                getBlob(
                                                  `${testIndex}${questionIndex}${choiceIndex}`,
                                                  'blob',
                                                  choice.blob
                                                )
                                              "
                                              @click="
                                                cacheQuestionsData(
                                                  'removeImage',
                                                  testIndex,
                                                  questionIndex,
                                                  'choice',
                                                  choiceIndex
                                                )
                                              "
                                              color="red accent-4"
                                              x-small
                                              :rounded="
                                                $vuetify.breakpoint.smAndUp
                                                  ? true
                                                  : false
                                              "
                                              :icon="
                                                $vuetify.breakpoint.xsOnly
                                                  ? true
                                                  : false
                                              "
                                              dark
                                            >
                                              <v-icon
                                                v-if="
                                                  $vuetify.breakpoint.xsOnly
                                                "
                                                size="25"
                                                >mdi-close-box</v-icon
                                              >
                                              <div v-else>Remove</div>
                                            </v-btn>
                                            <small v-else>
                                              Click
                                              <v-icon size="14"
                                                >mdi-camera</v-icon
                                              >
                                              to upload
                                            </small>
                                          </v-row>
                                          <v-skeleton-loader
                                            v-if="
                                              !getBlob(
                                                `${testIndex}${questionIndex}${choiceIndex}`,
                                                'preview',
                                                choice.preview
                                              )
                                            "
                                            class="mt-3"
                                            boilerplate
                                            type="image"
                                            max-height="150"
                                            max-width="250"
                                          ></v-skeleton-loader>
                                          <v-img
                                            v-else
                                            :src="
                                              getBlob(
                                                `${testIndex}${questionIndex}${choiceIndex}`,
                                                'preview',
                                                choice.preview
                                              )
                                            "
                                            class="mt-3"
                                            max-height="150"
                                            contain
                                          />
                                        </v-card-subtitle>
                                        <v-card-actions>
                                          <v-file-input
                                            class="my-n2"
                                            style="max-width: 20px"
                                            @change="
                                              handleFileUploads(
                                                'choice',
                                                testIndex,
                                                questionIndex,
                                                $event,
                                                choiceIndex
                                              )
                                            "
                                            :value="
                                              getBlob(
                                                `${testIndex}${questionIndex}${choiceIndex}`,
                                                'blob',
                                                choice.blob
                                              )
                                            "
                                            accept="image/*"
                                            dense
                                            hide-details
                                            hide-input
                                            solo
                                            prepend-icon="mdi-camera"
                                            :rules="[validators.required]"
                                          />
                                          <v-btn
                                            v-if="!choice.correct"
                                            absolute
                                            right
                                            @click="
                                              cacheQuestionsData(
                                                'correct',
                                                testIndex,
                                                questionIndex,
                                                true,
                                                choiceIndex
                                              )
                                            "
                                            shaped
                                            x-small
                                            color="grey"
                                            dark
                                          >
                                            <v-icon right size="15"
                                              >mdi-check</v-icon
                                            >
                                            {{
                                              $vuetify.breakpoint.smAndUp
                                                ? "Set as correct"
                                                : ""
                                            }}
                                          </v-btn>
                                          <v-btn
                                            v-else
                                            style="background-color: #1b5e20 !important; color: #fff !important;"
                                            absolute
                                            right
                                            shaped
                                            x-small
                                            disabled
                                            text
                                          >
                                            Correct
                                          </v-btn>
                                        </v-card-actions>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                              <v-row v-else>
                                <v-col
                                  v-for="(
                                    answer, answerIndex
                                  ) in question.answers"
                                  :key="answerIndex"
                                  cols="12"
                                >
                                  <v-textarea
                                    class="mb-n5"
                                    @change="
                                      cacheQuestionsData(
                                        'answer',
                                        testIndex,
                                        questionIndex,
                                        $event,
                                        answerIndex
                                      )
                                    "
                                    :value="answer.answer"
                                    dense
                                    :label="`Answer #${answerIndex + 1} : `"
                                    outlined
                                    auto-grow
                                    rows="1"
                                    :rules="[validators.required]"
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn
          class="px-4"
          @click="addTest"
          color="blue accent-4"
          dark
          depressed
          :ripple="false"
        >
          Add Test
        </v-btn>
        <v-btn
          class="px-4"
          @click="submitForm(assessmentForm, assessmentFormValid)"
          :disabled="!assessmentFormValid"
          :dark="assessmentFormValid"
          color="green accent-4"
          depressed
          :ripple="false"
        >
          {{ assessmentForm.isUpdating ? "Update" : "Submit" }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import CONSTANTS from "@/config/constants";
import validators from "@/helpers/validationHelper";
import notify from "@/utilities/notifications";
import mimetypes from "@/lib/utilities/mimetypes";
import imageHelper from "@/helpers/imageHelper";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "AssessmentForm",
  data() {
    return {
      submitLoading: false,
      //helpers
      notify: notify,
      mimetypes: mimetypes,
      validators: validators,
      imageHelper: imageHelper,
      testOptions: CONSTANTS.TEST_TYPES,
      //dialogs / menus
      resetFormDialog: false,
      expandSchedule: false,
      monthFromMenu: false,
      timeFromMenu: false,
      monthToMenu: false,
      timeToMenu: false,
      //file related
      uploadCount: 0,
      uploadProgress: 0,
      blobs: [],
      //assessment state
      assessmentFormValid: false,
      assessmentForm: {
        isUpdating: false,
        type: "",
        title: "",
        lesson: "",
        attempts: "",
        duration: 0,
        offline: false,
        accessCode: null,
        status: true,
        recorded: true,
        performanceTask: false,
        writtenWork: false,
        recordHighest: false,
        quarter: "",
        subjectId: "",
        from: null,
        monthFrom: null,
        timeFrom: null,
        to: null,
        monthTo: null,
        timeTo: null,
        assessmentTests: [
          {
            instruction: "",
            test: "",
            random: false,
            caseSensitivity: false,
            imageChoices: false,
            totalItems: "",
            questions: [],
            method: "AUTOMATIC",
            expand: true,
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      subject: (state) => state.subjectsModule.subject,
      quarterOptions: (state) => state.subjectsModule.quarterOptions,
      assessment: (state) => state.assessmentModule.assessment,
    }),
    type() {
      let type = this.$route.query.type;

      if (this.assessmentId) {
        type = this.assessment.type;
      }

      return type;
    },
    assessmentId() {
      return this.$route.query.id;
    },
    strict() {
      return this.$route.query.strict ?? false;
    },
  },
  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  async mounted() {
    //REMINDER : if form must be cache for each subject, then change localStorage.getItem(`assessmentForm-${this.subject.id}`)
    if (this.assessmentId && !this.strict) {
      this.assessmentForm["isUpdating"] = true;
      const getAssessment = await this.$store.dispatch(
        "assessmentModule/getAssessment",
        this.assessmentId
      );
      if (getAssessment) {
        if (localStorage.getItem(`assessmentForm-${this.subject.id}`)) {
          try {
            this.assessmentForm = JSON.parse(
              localStorage.getItem(`assessmentForm-${this.subject.id}`)
            );
          } catch (e) {
            localStorage.removeItem(`assessmentForm-${this.subject.id}`);
            this.assessmentForm = this.setupAssessmentForm(this.assessment);
          }
        } else {
          this.assessmentForm = this.setupAssessmentForm(this.assessment);
        }
      } else {
        notify({
          title: "Info",
          status: "info",
          message: "Failed to fetch assessment data, please try again",
        });
        this.$router.push({
          name: "Assessment",
        });
      }
    } else {
      if (localStorage.getItem(`assessmentForm-${this.subject.id}`)) {
        try {
          this.assessmentForm = JSON.parse(
            localStorage.getItem(`assessmentForm-${this.subject.id}`)
          );
        } catch (e) {
          localStorage.removeItem(`assessmentForm-${this.subject.id}`);
        }
      }

      this.assessmentForm["type"] = this.type ?? "activities";
      this.assessmentForm["isUpdating"] = false;
    }

    await this.mapImages(this.assessmentForm, false);

    this.expandSchedule =
      this.assessmentForm.from && this.assessmentForm.to ? true : false;
  },
  methods: {
    moment: moment,
    generateRandomKey() {
      var accessCode = Math.random().toString(36).slice(2);
      this.cacheData('accessCode', accessCode);
    },
    async mapImages(assessmentForm, mapChanges) {
      let tests = [...assessmentForm.assessmentTests];
      tests.map((test, testIndex) => {
        let questions = [...test.questions];
        questions.map(async (question, questionIndex) => {
          if (mapChanges) {
            if (
              (question.image != question.currentImage || !question.image) &&
              question.currentImage
            ) {
              await this.$store.dispatch(
                "assessmentModule/deleteFile",
                question.currentImage
              );
            }
          } else {
            if (question.image && assessmentForm.isUpdating) {
              this.assessmentForm.assessmentTests[testIndex].questions[
                questionIndex
              ].preview = await this.$store.dispatch(
                "assessmentModule/getFileUrl",
                question.image
              );
            } else {
              this.assessmentForm.assessmentTests[testIndex].questions[
                questionIndex
              ].preview = null;
              this.assessmentForm.assessmentTests[testIndex].questions[
                questionIndex
              ].blob = null;
              this.assessmentForm.assessmentTests[testIndex].questions[
                questionIndex
              ].currentImage = null;
            }
          }

          let choices = [...question.choices];
          if (test.imageChoices && assessmentForm.isUpdating) {
            choices.map(async (choice, choiceIndex) => {
              if (mapChanges) {
                if (
                  choice.choice != choice.currentImage &&
                  choice.currentImage
                ) {
                  await this.$store.dispatch(
                    "assessmentModule/deleteFile",
                    choice.currentImage
                  );
                }
              } else {
                this.assessmentForm.assessmentTests[testIndex].questions[
                  questionIndex
                ].choices[choiceIndex].preview = await this.$store.dispatch(
                  "assessmentModule/getFileUrl",
                  choice.choice
                );
              }
            });
          } else {
            choices.map(async (choice, choiceIndex) => {
              this.assessmentForm.assessmentTests[testIndex].questions[
                questionIndex
              ].choices[choiceIndex].preview = null;
              this.assessmentForm.assessmentTests[testIndex].questions[
                questionIndex
              ].choices[choiceIndex].blob = null;
              this.assessmentForm.assessmentTests[testIndex].questions[
                questionIndex
              ].choices[choiceIndex].currentImage = null;
            });
          }
        });
      });
    },
    sanitizeDate(date) {
      let month = null,
        time = null,
        dateTime = null;

      if (date) {
        let sanitizedDate = moment(date).format("YYYY-MM-DD HH:mm");
        month = sanitizedDate.split(" ")[0];
        time = sanitizedDate.split(" ")[1];
        dateTime = sanitizedDate;
      }

      return {
        month,
        time,
        dateTime,
      };
    },
    setupAssessmentForm(assessment) {
      let sanitizedDateFrom = this.sanitizeDate(assessment.from),
        sanitizedDateTo = this.sanitizeDate(assessment.to),
        assessmentForm = {
          ...assessment,
          isUpdating: true,
          from: sanitizedDateFrom.dateTime,
          monthFrom: sanitizedDateFrom.month,
          timeFrom: sanitizedDateFrom.time,
          to: sanitizedDateTo.dateTime,
          monthTo: sanitizedDateTo.month,
          timeTo: sanitizedDateTo.time,
        };
      delete assessmentForm.teacher;
      delete assessmentForm.createdAt;

      let tests = assessment.assessmentTests?.map((test) => {
        let method = this.testOptions["AUTOMATIC"].find(
          (type) => type.value == test.test
        )
          ? "AUTOMATIC"
          : "MANUAL";
        let questions = test.questions.map((question) => {
          let blob = null,
            preview = null,
            currentImage = null,
            answers = [{ answer: "" }],
            choices = [
              {
                choice: "",
                correct: true,
                blob: null,
                currentImage: null,
                preview: null,
              },
              {
                choice: "",
                correct: false,
                blob: null,
                currentImage: null,
                preview: null,
              },
              {
                choice: "",
                correct: false,
                blob: null,
                currentImage: null,
                preview: null,
              },
            ];

          if (question.image) {
            blob = { name: question.image };
            currentImage = question.image;
          }

          if (method === "AUTOMATIC") {
            if (test.test === "multiplechoice") {
              choices = question.choices.map((choice) => {
                let blob = null,
                  preview = null,
                  currentImage = null;

                if (test.imageChoices) {
                  blob = { name: choice.choice };
                  currentImage = choice.choice;
                }

                choice = { ...choice, blob, preview, currentImage };
                return choice;
              });
            } else {
              answers = question.answers;
            }
          }

          question = {
            ...question,
            blob,
            preview,
            currentImage,
            answers,
            choices,
          };

          return question;
        });

        test = {
          ...test,
          totalItems: questions.length,
          questions,
          method,
          expand: true,
        };
        return test;
      });

      return { ...assessmentForm, assessmentTests: tests };
    },
    divisibleByEnumerateCount(points, enumerateCount, test) {
      if (test === "enumeration") {
        return points % enumerateCount === 0 ? true : "Invalid";
      } else {
        return true;
      }
    },
    confirmLeave() {
      return window.confirm();
    },

    confirmStayInDirtyForm() {
      return this.blobs.length > 0 && !this.confirmLeave();
    },

    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    },
    minTime() {
      let currentDate = new Date();
      return currentDate;
    },
    requireTimeInput(month, time) {
      if (month) {
        return time ? true : "Input time";
      }

      return true;
    },
    async submitForm(assessmentForm, assessmentFormValid) {
      if (assessmentFormValid) {
        this.assessmentForm["subjectId"] = this.subject.id;
        this.uploadProgress = 0;
        this.uploadCount = 0;
        this.submitLoading = true;
        const sanitizedForm = this.sanitizeForm(assessmentForm),
          query = {
            quarter: assessmentForm.quarter,
            type: assessmentForm.type,
          };

        let submitAssessment = await this.$store.dispatch(
          "assessmentModule/submitAssessment",
          sanitizedForm
        );

        //Only upload files if form submitted successfully
        if (submitAssessment) {
          let blobs = this.blobs;
          if (blobs.length > 0) {
            for (let index = 0; index < blobs.length; index++) {
              let totalUploads = blobs.length,
                percentage = 100 / totalUploads;

              await this.$store.dispatch(
                "assessmentModule/uploadFileToFirebase",
                blobs[index].blob
              );
              this.uploadCount = index + 1;
              this.uploadProgress += Math.ceil(percentage);
              // await new Promise((resolve) => setTimeout(resolve, 1000));
            }

            this.uploadCount = 0;
          }

          //delete changed or removed images
          await this.mapImages(assessmentForm, true);

          await new Promise((resolve) => setTimeout(resolve, 1000));
          notify({
            title: "Success",
            status: "success",
            message: `Assessment ${
              assessmentForm.isUpdating ? "updated" : "submitted"
            } successfully`,
          });
          this.resetFields();

          await this.$store.dispatch(
            "assessmentModule/getAssessments",
            this.subject.id
          );

          this.$router.push({
            name: "Assessment",
            query,
          });
        } else {
          notify({
            title: "Error",
            status: "error",
            message: `Failed to ${
              assessmentForm.isUpdating ? "update" : "submit"
            } assessment`,
          });
        }

        this.submitLoading = false;
      } else {
        notify({
          title: "Error",
          status: "error",
          message: "Form validation failed",
        });
      }
    },
    sanitizeForm(assessmentForm) {
      let tests = assessmentForm.assessmentTests?.map((test) => {
        let questions = test.questions.map((question) => {
          let choices = question.choices.map((choice) => {
            choice = {
              choice: choice.choice,
              correct: choice.correct,
            };
            return choice;
          });
          question = {
            question: question.question,
            enumerateCount: question.enumerateCount,
            image: question.image,
            points: question.points,
            answers: question.answers,
            choices,
          };
          return question;
        });

        test = { ...test, questions: questions };
        return test;
      });

      const sanitizedForm = { ...assessmentForm, assessmentTests: tests };
      return sanitizedForm;
    },
    resetFields() {
      localStorage.removeItem(`assessmentForm-${this.subject.id}`);

      this.blobs = [];
      this.expandSchedule = false;
      this.monthFromMenu = false;
      this.timeFromMenu = false;
      this.monthToMenu = false;
      this.timeToMenu = false;
      this.assessmentFormValid = false;
      this.assessmentForm = {
        isUpdating: false,
        type: this.type,
        title: "",
        lesson: "",
        attempts: "",
        duration: 0,
        status: true,
        recorded: true,
        performanceTask: false,
        writtenWork: false,
        recordHighest: false,
        quarter: "",
        from: null,
        monthFrom: null,
        timeFrom: null,
        to: null,
        monthTo: null,
        timeTo: null,
        assessmentTests: [
          {
            instruction: "",
            test: "",
            random: false,
            caseSensitivity: false,
            imageChoices: false,
            totalItems: "",
            questions: [],
            method: "AUTOMATIC",
            expand: true,
          },
        ],
      };
    },
    async resetForm() {
      if (this.assessmentForm.isUpdating) {
        this.assessmentForm = this.setupAssessmentForm(this.assessment);
      } else {
        this.resetFields();
      }

      await this.mapImages(this.assessmentForm, false);
      this.resetFormDialog = false;
      notify({
        title: "Success",
        status: "success",
        message: "Form reset successfully",
      });
    },
    addAction(test, testIndex, questionIndex) {
      let questions = [
        ...this.assessmentForm.assessmentTests[testIndex].questions,
      ];

      switch (test) {
        case "multiplechoice":
          let choices = [...questions[questionIndex].choices],
            addChoice = {
              choice: "",
              correct: false,
              blob: null,
              currentImage: null,
              preview: null,
            };
          //only allow addition if choices are less than 5
          if (choices.length < 5) {
            choices.push(addChoice);

            questions[questionIndex] = {
              ...questions[questionIndex],
              choices,
            };
          } else {
            notify({
              title: "Info",
              status: "info",
              message: "Maximum of 5 choices",
            });
          }
          break;
        default:
          let answers = [...questions[questionIndex].answers],
            addAnswer = { answer: "" },
            enumerateCount = answers.length,
            addCount = enumerateCount + 1;

          answers.push(addAnswer);

          questions[questionIndex] = {
            ...questions[questionIndex],
            answers,
            enumerateCount: addCount,
          };
          break;
      }

      this.assessmentForm.assessmentTests[testIndex].questions = questions;

      this.setAssessmentForm();
    },
    removeAction(test, testIndex, questionIndex) {
      let questions = [
        ...this.assessmentForm.assessmentTests[testIndex].questions,
      ];

      switch (test) {
        case "multiplechoice":
          let choices = [...questions[questionIndex].choices];
          //only allow deduction if choices are more than 2
          if (choices.length > 2) {
            //check last choice if correct is true
            const lastChoiceIndex = choices.findLastIndex((choice) => choice);
            if (choices[lastChoiceIndex].correct) {
              //transfer correct before removing last choice
              let beforeLast = lastChoiceIndex - 1;
              choices[beforeLast] = { ...choices[beforeLast], correct: true };
            }
            //remove last choice
            choices.splice(lastChoiceIndex, 1);

            questions[questionIndex] = {
              ...questions[questionIndex],
              choices,
            };
          } else {
            notify({
              title: "Info",
              status: "info",
              message: "Minimum of 2 choices",
            });
          }
          break;
        default:
          let answers = [...questions[questionIndex].answers];
          //only allow deduction if answers are more than 1
          if (answers.length > 1) {
            let enumerateCount = answers.length,
              reduceCount = enumerateCount - 1;
            const lastAnswerIndex = answers.findLastIndex((answer) => answer);
            //remove last answer
            answers.splice(lastAnswerIndex, 1);

            questions[questionIndex] = {
              ...questions[questionIndex],
              answers,
              enumerateCount: reduceCount,
            };
          }
          break;
      }

      this.assessmentForm.assessmentTests[testIndex].questions = questions;

      this.setAssessmentForm();
    },
    intToChar(int) {
      const code = "a".charCodeAt(0);

      return String.fromCharCode(code + int);
    },
    removeTest(testIndex) {
      this.assessmentForm.assessmentTests.splice(testIndex, 1);
    },
    addTest() {
      let test = {
        instruction: "",
        test: "",
        random: false,
        caseSensitivity: false,
        imageChoices: false,
        totalItems: "",
        questions: [],
        method: "AUTOMATIC",
        expand: true,
      };

      this.assessmentForm.assessmentTests.push(test);
    },
    testType(method, value) {
      let type = this.testOptions[method].find((type) => type.value == value);
      return type.text;
    },
    getBlob(key, attr, currentValue) {
      let blobs = [...this.blobs],
        value = currentValue;

      if (blobs.length > 0) {
        blobs.find((blob) => {
          if (blob["ref"] === key) {
            value = blob[attr];
          }
        });
      }

      return value;
    },
    getBlobIndex(key) {
      let blobs = [...this.blobs],
        blobIndex = null;

      blobs.find((blob, index) => {
        if (blob["ref"] === key) {
          blobIndex = index;
        }
      });

      return blobIndex;
    },
    async handleFileUploads(
      key,
      testIndex,
      questionIndex,
      value,
      index = null
    ) {
      if (value instanceof Blob) {
        if (mimetypes.image.includes(value.type)) {
          let dataUrl = await imageHelper.resizeImage(value),
            questions = [
              ...this.assessmentForm.assessmentTests[testIndex].questions,
            ],
            blobs = [...this.blobs];

          if (dataUrl) {
            let blob = await imageHelper.dataURLToBlob(dataUrl),
              timestamp = Date.now(),
              fileExtension = value.type.replace("image/", ".");
            if (blob) {
              switch (key) {
                case "question":
                  blob.name = `${questionIndex}${timestamp.toString()}${fileExtension}`;

                  questions[questionIndex] = {
                    ...questions[questionIndex],
                    image: blob.name,
                  };

                  let questionBlobIndex = this.getBlobIndex(
                    `${testIndex}${questionIndex}`
                  );

                  if (questionBlobIndex !== null) {
                    blobs[questionBlobIndex] = {
                      ...blobs[questionBlobIndex],
                      blob,
                      preview: dataUrl,
                    };
                  } else {
                    blobs.push({
                      ref: `${testIndex}${questionIndex}`,
                      blob,
                      preview: dataUrl,
                    });
                  }

                  break;
                case "choice":
                  blob.name = `${index}${questionIndex}${timestamp.toString()}${fileExtension}`;
                  let choices = [...questions[questionIndex].choices];
                  choices[index] = {
                    ...choices[index],
                    [key]: blob.name,
                  };

                  questions[questionIndex] = {
                    ...questions[questionIndex],
                    choices,
                  };

                  let choiceBlobIndex = this.getBlobIndex(
                    `${testIndex}${questionIndex}${index}`
                  );

                  if (choiceBlobIndex !== null) {
                    blobs[choiceBlobIndex] = {
                      ...blobs[choiceBlobIndex],
                      blob,
                      preview: dataUrl,
                    };
                  } else {
                    blobs.push({
                      ref: `${testIndex}${questionIndex}${index}`,
                      blob,
                      preview: dataUrl,
                    });
                  }

                  break;
              }
            }

            this.blobs = blobs;
            this.assessmentForm.assessmentTests[testIndex].questions =
              questions;

            this.setAssessmentForm();
          }
        } else {
          notify({
            title: "Info",
            status: "info",
            message:
              "Please upload a valid image, accepted images are [.jpeg, .png, .webp, .bmp]",
          });
        }
      }
    },
    cacheQuestionsData(key, testIndex, questionIndex, value, index = null) {
      let questions = [
          ...this.assessmentForm.assessmentTests[testIndex].questions,
        ],
        choices = [...questions[questionIndex].choices],
        answers = [...questions[questionIndex].answers];

      switch (key) {
        case "removeImage":
          switch (value) {
            case "question":
              questions[questionIndex] = {
                ...questions[questionIndex],
                image: null,
                blob: null,
                preview: null,
              };

              let questionBlobIndex = this.getBlobIndex(
                `${testIndex}${questionIndex}`
              );

              if (questionBlobIndex !== null) {
                this.blobs.splice(questionBlobIndex, 1);
              }

              break;
            case "choice":
              choices[index] = {
                ...choices[index],
                choice: "",
                blob: null,
                preview: null,
              };
              questions[questionIndex] = {
                ...questions[questionIndex],
                choices,
              };

              let choiceBlobIndex = this.getBlobIndex(
                `${testIndex}${questionIndex}${index}`
              );

              if (choiceBlobIndex !== null) {
                this.blobs.splice(choiceBlobIndex, 1);
              }

              break;
          }
          break;
        case "answer":
          answers[index] = { ...answers[index], [key]: value };
          questions[questionIndex] = {
            ...questions[questionIndex],
            answers,
          };
          break;
        case "choice":
          choices[index] = {
            ...choices[index],
            choice: value,
          };
          questions[questionIndex] = {
            ...questions[questionIndex],
            choices,
          };
          break;
        case "correct":
          choices.map((choice, correctIndex) => {
            if (correctIndex === index) {
              choices[correctIndex] = { ...choice, [key]: value };
            } else {
              choices[correctIndex] = { ...choice, [key]: false };
            }
          });

          questions[questionIndex] = {
            ...questions[questionIndex],
            choices,
          };
          break;
        case "enumerateCount":
          let addAnswer = { answer: "" };
          if (value > 0) {
            if (answers.length > value) {
              let diff = answers.length - value;

              answers.splice(value, diff);
            } else {
              let add = value - answers.length,
                addAnswers = Array(add).fill(addAnswer);

              answers = [...answers, ...addAnswers];
            }

            questions[questionIndex] = {
              ...questions[questionIndex],
              enumerateCount: value,
              points: value,
              answers,
            };
          }
          break;
        default:
          questions[questionIndex] = {
            ...questions[questionIndex],
            [key]: value,
          };
          break;
      }

      this.assessmentForm.assessmentTests[testIndex].questions = questions;

      this.setAssessmentForm();
    },
    setTestQuestions(testIndex, currentItems, total) {
      let question = {
        question: "",
        enumerateCount: null,
        image: null,
        blob: null,
        currentImage: null,
        preview: null,
        points: 1,
        choices: [
          {
            choice: "",
            correct: true,
            blob: null,
            currentImage: null,
            preview: null,
          },
          {
            choice: "",
            correct: false,
            blob: null,
            currentImage: null,
            preview: null,
          },
          {
            choice: "",
            correct: false,
            blob: null,
            currentImage: null,
            preview: null,
          },
        ],
        answers: [{ answer: "" }],
      };

      if (currentItems > 0) {
        if (currentItems > total) {
          let diff = currentItems - total;
          this.assessmentForm.assessmentTests[testIndex].questions.splice(
            total,
            diff
          );
        } else {
          let add = total - currentItems,
            questions = Array(add).fill(question);
          this.assessmentForm.assessmentTests[testIndex].questions = [
            ...this.assessmentForm.assessmentTests[testIndex].questions,
            ...questions,
          ];
        }
      } else {
        let questions = Array(total).fill(question);
        this.assessmentForm.assessmentTests[testIndex].questions = questions;
      }
    },
    cacheTestsData(key, testIndex, value) {
      this.assessmentForm.assessmentTests[testIndex][key] = value;
      switch (key) {
        case "method":
          this.assessmentForm.assessmentTests[testIndex].test = "";
          break;
        case "test":
          this.assessmentForm.assessmentTests[testIndex].random = false;
          this.assessmentForm.assessmentTests[
            testIndex
          ].caseSensitivity = false;
          this.assessmentForm.assessmentTests[testIndex].imageChoices = false;
          break;
        case "imageChoices":
          if (!value) {
            this.blobs = [];
          }
          break;
        case "totalItems":
          let currentItems =
            this.assessmentForm.assessmentTests[testIndex].questions?.length ??
            0;

          if (this.validators.requiredNumber(value) === true) {
            const total = value ? parseInt(value) : 0;
            this.setTestQuestions(testIndex, currentItems, total);
          } else {
            this.assessmentForm.assessmentTests[testIndex][key] =
              currentItems.toString();
          }
          break;
        default:
          break;
      }
      this.setAssessmentForm();
    },
    clearSchedule() {
      this.assessmentForm.from = null;
      this.assessmentForm.monthFrom = null;
      this.assessmentForm.timeFrom = null;
      this.assessmentForm.to = null;
      this.assessmentForm.monthTo = null;
      this.assessmentForm.timeTo = null;
    },
    cacheData(key, value) {
      let monthFrom = this.assessmentForm.monthFrom,
        monthTo = this.assessmentForm.monthTo,
        timeFrom = this.assessmentForm.timeFrom,
        timeTo = this.assessmentForm.timeTo;

      switch (key) {
        case "offline":
          if (!value) {
            this.assessmentForm.accessCode = null;
          }
          this.assessmentForm[key] = value;
          break;
        case "monthFrom":
          if (monthTo && value > monthTo) {
            this.assessmentForm.to = null;
            this.assessmentForm.monthTo = null;
            this.assessmentForm.timeTo = null;
          }

          this.assessmentForm.monthFrom = value;
          this.assessmentForm.from = this.assessmentForm.monthFrom.concat(
            ` ${this.assessmentForm.timeFrom ?? ""}`
          );
          this.monthFromMenu = false;
          break;
        case "timeFrom":
          if (monthFrom == monthTo && value > timeTo) {
            this.assessmentForm.to = null;
            this.assessmentForm.monthTo = null;
            this.assessmentForm.timeTo = null;
          }

          this.assessmentForm.timeFrom = value;
          this.assessmentForm.from = this.assessmentForm.monthFrom.concat(
            ` ${this.assessmentForm.timeFrom}`
          );
          this.timeFromMenu = false;
          break;
        case "monthTo":
          if (value >= monthFrom) {
            this.assessmentForm.monthTo = value;
            this.assessmentForm.to = this.assessmentForm.monthTo.concat(
              ` ${this.assessmentForm.timeTo ?? ""}`
            );
          } else {
            notify({
              title: "Info",
              status: "info",
              message: `Please input date later than or equal to ${monthFrom}`,
            });
          }

          this.monthToMenu = false;
          break;
        case "timeTo":
          if (monthFrom == monthTo && value > timeFrom) {
            this.assessmentForm.timeTo = value;
            this.assessmentForm.to = this.assessmentForm.monthTo.concat(
              ` ${this.assessmentForm.timeTo}`
            );
          }
          this.timeToMenu = false;
          break;
        case "recorded":
          if (!value) {
            this.assessmentForm.performanceTask = false;
            this.assessmentForm.writtenWork = false;
          }
          this.assessmentForm[key] = value;
          break;
        case "test":
          if (value === "multiplechoice") {
            this.assessmentForm.caseSensitivity = false;
          } else {
            if (value === "matchingtype" || value === "trueorfalse") {
              this.assessmentForm.caseSensitivity = false;
            }
            this.assessmentForm.imageChoices = false;
          }
          this.assessmentForm[key] = value;
          break;
        default:
          this.assessmentForm[key] = value;
          break;
      }
      this.setAssessmentForm();
    },
    setAssessmentForm() {
      const parsed = JSON.stringify(this.assessmentForm);
      localStorage.setItem(`assessmentForm-${this.subject.id}`, parsed);
    },
    ucFirst(value) {
      return value.charAt(0).toUpperCase() + value.substring(1);
    },
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
};
</script>
